<template>
  <div
      :class="[!dataLoaded && 'overlay overlay-block']"
      class="card card-xxl-stretch mb-5 mb-xxl-12"
  >
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="listInfo">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label text-primary fw-bold fs-1 mb-2 text-center text-uppercase">{{listInfo.description}}</span>
      </h3>
      <div class="card-toolbar">
        <span class="mt-1 fw-bold fs-5 text-right badge-light-primary badge fw-bolder me-auto px-4 py-3">{{ listInfo.modified }}</span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-3">
      <div
          class="overlay-layer rounded bg-white bg-opacity-5"
          :class="[dataLoaded && 'd-none']"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
            class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
            v-if="listInfo"
        >
          <!--begin::Table head-->
          <thead>
          <tr class="border-0">
            <th class="p-0 w-40px"></th>
            <th class="p-0"></th>
          </tr>
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index) in listInfo.list" :key="index">
              <tr>
                <td class="text-start" v-if="item.type === 'Question'">
                  <div class="symbol symbol-50px">
                    <div class="symbol-label fs-2 fw-bold text-primary">{{item.id}}</div>
                  </div>
                </td>
                <td :colspan="[item.type !== 'Question' && 2]" :class="{'text-start fw-normal text-dark fs-4': item.type === 'Question', 'text-center fw-bolder text-primary fs-2 mb-1 text-uppercase': item.type !== 'Question' }">
                  {{ item.item }}
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>


<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";
import {useRoute} from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "ddb-checklist-view",
  components: {
  },
  methods: {
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/documents/checklists/list/"+this.listId)
          .then(data => {
            //console.log(data);
            //update project tabs table
            //this.title = "Assets details filled with AJAX data";
            this.$nextTick(() => {
              this.listInfo = data.data.data;
              setCurrentPageTitle(this.listInfo.title.replace('PRED-QHSE', 'SG-QHSE'));
              setCurrentPageBreadcrumbs(this.listInfo.title.replace('PRED-QHSE', 'SG-QHSE'), ["Documents DB", "Checklists"]);
              setCurrentPageToolbarType("ddb-checklist-view");
            });
            this.dataLoaded = true;
          })
          .catch(response => {
            //console.log(response);
          });
    }
  },
  created () {
    const route = useRoute();
    this.listId = route.params.listId;
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Checklist view");
    });
  },
  data() {
    let listId: any;
    let listInfo: any;
    const dataLoaded = false;
    return {listId, listInfo, dataLoaded}
  }
});
</script>
