
import { defineComponent, onMounted, onBeforeMount } from "vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";
import {useRoute} from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "ddb-checklist-view",
  components: {
  },
  methods: {
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/documents/checklists/list/"+this.listId)
          .then(data => {
            //console.log(data);
            //update project tabs table
            //this.title = "Assets details filled with AJAX data";
            this.$nextTick(() => {
              this.listInfo = data.data.data;
              setCurrentPageTitle(this.listInfo.title.replace('PRED-QHSE', 'SG-QHSE'));
              setCurrentPageBreadcrumbs(this.listInfo.title.replace('PRED-QHSE', 'SG-QHSE'), ["Documents DB", "Checklists"]);
              setCurrentPageToolbarType("ddb-checklist-view");
            });
            this.dataLoaded = true;
          })
          .catch(response => {
            //console.log(response);
          });
    }
  },
  created () {
    const route = useRoute();
    this.listId = route.params.listId;
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Checklist view");
    });
  },
  data() {
    let listId: any;
    let listInfo: any;
    const dataLoaded = false;
    return {listId, listInfo, dataLoaded}
  }
});
